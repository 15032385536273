@import "/src/shared/assets/styles/base/colors.scss";
@import "/src/shared/assets/styles/mixins/fontsMixins.scss";

.board {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-x: hidden;
  max-width: calc(100% - 5px);
}

.itemList {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 12px;
}

.separatingBorder {
  border-bottom: 1px solid $blue-azure;
  padding-bottom: 12px;
  margin-bottom: 12px;
  position: sticky;
  background-color: $bw-white;
  top: 0;
  z-index: 1;
}

.loadingText,
.noItemsFound {
  @include listMainText;
}

.noItemsFound {
  margin: 0;
  padding: 6px 0 10px 10px;
  cursor: default;
}

.loaderContainer {
  min-height: 100px;
}
