@import "/src/shared/assets/styles/base/colors.scss";
@import "src/shared/assets/styles/mixins/fontsMixins.scss";

.staff {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.staffInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.workshift {
  position: absolute;
  right: 8px;
  display: flex;
  align-items: baseline;
  gap: 8px;
}

.workshiftTitle {
  @include caption;
  color: $bw-gray6;
}

.workshiftCount {
  @include subtitle;
}

.link {
  text-decoration: none;
  color: $blue-lazure;
  font-weight: 400;
}

.company {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 12px;
}

.event {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.emptyTN {
  color: $bw-gray6;
}
