@import "/src/shared/assets/styles/base/colors.scss";

.staff {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.link {
  text-decoration: none;
  color: $blue-lazure;
  font-weight: 400;
}

.company {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.event {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

