@import "/src/shared/assets/styles/base/colors.scss";

.td {
  padding: 16px 8px;
  border: 1px solid $bw-gray3;
  vertical-align: baseline;

  &::after {
    background-color: $bw-gray3 !important;
    width: 0.9px !important;
  }
}

.field {
  display: grid;
  grid-template-columns: 1fr 24px;
  gap: 10px;
  align-items: flex-start;
}
