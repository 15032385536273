@import "src/shared/assets/styles/mixins/fontsMixins.scss";
@import "src/shared/assets/styles/mixins/mainMixins.scss";
@import "src/shared/assets/styles/base/colors.scss";

.table {
  @include mainText;
  margin-left: -31px !important;
  border-collapse: separate;
  border-spacing: 0;
}

.thead {
  background-color: $bw-white;
  border-top: 1px solid $bw-gray3;
  border-bottom: 2px solid $bw-gray3;
}

.th {
  @include caption;
  white-space: nowrap;
  color: $bw-gray6;
  cursor: pointer;
  padding: 12px 8px;
  border: 1px solid $bw-gray3;
  box-sizing: border-box;

  &::after {
    content: none !important;
  }
}

.tableRow {
  cursor: pointer;
  &:hover {
    background: $blue-white-blue;
  }
}

.greyRow {
  background-color: $bw-gray2;
}

.td {
  padding: 16px 8px;
  border: 1px solid $bw-gray3;
  vertical-align: baseline;

  &::after {
    background-color: $bw-gray3 !important;
    width: 0.9px !important;
  }
}

.candidateBirthday {
  display: flex;
  justify-content: space-between;
}

.birthday {
  color: $bw-gray6;
}

.error {
  & td {
    border-bottom: none;
    padding-left: 30px;
  }
}

.loading {
  position: relative;
  height: 50px;
  & > div {
    background-color: transparent;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
