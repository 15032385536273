@import "shared/assets/styles/base/colors.scss";
@import "shared/assets/styles/base/sizes.scss";
@import "shared/assets/styles/mixins/fontsMixins.scss";
@import "shared/assets/styles/mixins/mainMixins.scss";

.container {
  position: -webkit-sticky;
  position: sticky;
  left: 30px;
  z-index: 100;

  &_withOpenedMenu {
    width: calc(#{$maxWidth} - #{$openedAsideMenu} - 60px);
  }

  &_withClosedMenu {
    width: calc(#{$maxWidth} - #{$closedAsideMenu});
  }
}

.mainInfo {
  width: 100%;
  height: 100%;
  background-color: $bw-white;
  border-bottom: 1px solid $bw-gray3;
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  padding-bottom: 12px;
  margin-bottom: 20px;

  &__textinfo {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    gap: 12px;
  }
}

.photo {
  width: 53px;
  height: 53px;
  transition: all 0.4s ease;

  & img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
  }

  &_avatar {
    background-color: $bw-gray2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
    height: 95%;
  }
}

.TNBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 53px;
  border: 1px solid $bw-gray4;
  padding: 4px 12px;
  border-radius: 4px;
  gap: 10px;
  width: max-content;

  &__title {
    @include title;
    font-weight: 400;
    @include zero;
    color: $bw-gray6;
    line-height: 23.6px;
  }

  &__value {
    @include title;
    @include zero;
    color: $bw-gray7;
  }
}

.fioBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__fio,
  &_position {
    @include zero;
  }

  &__fio {
    @include title;
  }

  &__position {
    @include mainText;
  }
}

.search {
  margin-bottom: 20px;
  position: relative;
}

.companies {
  margin-top: 20px;

  & > p {
    margin: 0;
    @include listMainText;
    color: $bw-gray6;
  }

  &__buttons {
    display: flex;
    column-gap: 40px;
    margin: 8px 0 21px 0;
  }

  &__buttonsContainer {
    display: flex;
    column-gap: 4px;
  }
}

.addButton {
  margin-left: -15px;
  margin-top: 6px;
}

.addButtonText {
  width: max-content;
}
